import React, { useEffect, useState } from 'react';
import { Heart } from 'lucide-react';
import axios from 'axios'; // Import axios for making requests

const WeddingWebsite = () => {
  const weddingEvents = [
    { name: 'Mehendi Ceremony', date: 'November 16, 2024', time: '10:00 AM', location: 'Pacific Inn Riverside Patio' },
    { name: 'Sangeet Night', date: 'November 16, 2024', time: '7:00 PM', location: 'Pacific Inn Lawn' },
    { name: 'Haldi Ceremony', date: 'November 17, 2024', time: '9:00 AM', location: 'Glampers Resort Lawn' },
    { name: 'Reception', date: 'October 17, 2024', time: '7:00 PM', location: 'Sunshine Garden Resort' }
  ];

  const carouselImages = [
    '/image-1.jpeg',
    '/image-2.jpeg',
    '/image-3.jpeg',
    '/image-4.jpeg',
    '/image-5.jpeg',
    '/image-6.jpeg',
    '/image-7.jpeg',
    // Add more image paths as needed
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [uploadError, setUploadError] = useState(null); // For handling errors
  const [collageImages, setCollageImages] = useState([]); // Store images for collage
  const [uploadProgress, setUploadProgress] = useState(0); // New state for upload progress
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        (prevIndex + 1) % carouselImages.length
      );
    }, 2500); // Change image every 8 seconds

    // Fetch images from the backend on component mount
    const fetchCollageImages = async () => {
      try {
        const response = await axios.get('https://backend.punnuxdj.life/collage-images');
        
        if (response.data['images'].length === 6) {
         
          setCollageImages(response.data['images']); // Only set if exactly 6 images
        }
      } catch (error) {
        console.error('Error fetching collage images:', error);
      }
    };

    fetchCollageImages(); // Call to fetch images on load

    return () => clearInterval(interval);
  }, [carouselImages.length]);

  useEffect(() => {
    const targetDate = new Date('2024-11-16T11:00:00'); // Target date and time

    const updateCountdown = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setCountdown({ days, hours, minutes, seconds });
      } else {
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 }); // Countdown finished
      }
    };

    const interval = setInterval(updateCountdown, 1000);
    updateCountdown(); // Initial call to set the countdown immediately

    return () => clearInterval(interval);
  }, []);

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('images', file);
    });

    setUploadError(null);
    setUploadProgress(0); // Reset progress before starting upload

    try {
      await axios.post('https://backend.punnuxdj.life/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });

      setUploadError(null);
      setUploadProgress(100); // Set to 100% when complete
      // You might want to fetch updated collage images here
    } catch (error) {
      setUploadError('File upload failed. Please try again.');
      console.error('Upload error:', error);
    } finally {
      // Reset progress after a short delay
      setTimeout(() => setUploadProgress(0), 2000);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-pink-50 to-red-50 overflow-x-hidden"> {/* Added overflow-x-hidden */}
      <nav className="bg-transparent relative z-10">
        <div className="container mx-auto px-0 py-3"> {/* Changed px-6 to px-0 */}
          <div className="flex justify-between items-center">
            <div className="text-2xl font-light text-[#21425d]"> {/* Changed text color */}
              <Heart className="inline-block mr-2 pl-2 md:pl-0" size={32} />
              Two States, Two Heart: One Love
            </div>
            <div className="hidden md:flex space-x-6">
              <a href="#story" className="text-[#21425d] hover:text-[#1a2b3d] transition duration-300">Our Story</a> {/* Changed text color */}
              <a href="#gallery" className="text-[#21425d] hover:text-[#1a2b3d] transition duration-300">Gallery</a> {/* Changed text color */}
              <a href="#itinerary" className="text-[#21425d] hover:text-[#1a2b3d] transition duration-300">Itinerary</a> {/* Changed text color */}
              <a href="#rsvp" className="text-[#21425d] hover:text-[#1a2b3d] transition duration-300">RSVP</a> {/* Changed text color */}
            </div>
          </div>
        </div>
      </nav>

      <div>
        {/* Moved carousel section here */}
        
        <section className="w-full relative h-[75vh] mt-0"> {/* Changed h-screen to h-[75vh] */}
          {carouselImages.map((img, index) => (
            <img 
              key={index}
              src={img} 
              alt={`Wedding image ${index + 1}`} 
              className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-[3000ms] ease-in-out ${ // Changed to absolute positioning
                index === currentImageIndex ? 'opacity-100' : 'opacity-0'
              }`}
            />
          ))}
        </section>

        {/* New section for countdown timer */}
        <section className="flex justify-center items-center px-4 sm:px-8 md:px-12 lg:px-16 mt-8 mb-8"> {/* Standardized spacing */}
          <div className="flex justify-between w-full max-w-md space-x-4">
            {['Days', 'Hours', 'Minutes', 'Seconds'].map((label, index) => (
              <div key={index} className="bg-[#21425d] text-white p-3 rounded-lg flex-1 flex flex-col items-center">
                <span className="text-3xl font-bold">
                  {countdown[label.toLowerCase()]}
                </span>
                <div>{label}</div>
              </div>
            ))}
          </div>
        </section> {/* End of countdown section */}

        <div className="flex flex-col items-center text-center w-full p-4 mb-8"> {/* Standardized spacing */}
          <p className="text-xl md:text-3xl text-[#21425d] mb-6">
            We're counting down to 'I do' – are you counting with us?
          </p>
          <div className="mt-2 flex justify-center">
            <button 
              className="bg-[#21425d] text-white text-xl font-light py-3 px-8 rounded hover:bg-[#1a2b3d] transition duration-300"
              onClick={() => window.open('https://forms.gle/akjXQrnHBzjeDV4M9', '_blank')}
            >
              RSVP Now
            </button>
          </div>
        </div>

        
        <section className="my-8 mx-8 md:mx-16"> {/* Standardized spacing */}
          <h2 className="text-3xl md:text-4xl font-semibold text-[#21425d] mb-8 text-center">Our Story</h2> 
            <div className="text-center">
              <p className="text-lg text-gray-700 mb-4">
                From Uttarakhand's peaks, fair Punnu came,
                While DJ's soul in Kolkata did dwell.
                Though strangers once, fate whispered each one's name,
                As Covid's shadow o'er the world then fell.
                <br />
                <br />
                Through digital realms, their hearts began to soar,
                Two cultures blending in a dance divine.
                She shared the mountain's strength, he Bengal's lore,
                Their love, like rivers, did their lives entwine.
                <br />
                <br />
                Through trials they fought, their bond grew ever strong,
                O'er distance, doubt, and difference they rose.
                Their story sang a sweet, triumphant song,
                A tale of love that everyone now knows.
                <br />
                <br />
                On wedding day, 'neath skies both clear and bright,
                Their union showed love's pure, unfaltering light.
              </p>
            </div>
        </section>

        <section className="my-8 mx-0 md:mx-16"> {/* Standardized spacing */}
          <h2 className="text-3xl md:text-4xl font-semibold text-[#21425d] mb-8 text-center">Wedding Itinerary</h2>
          
          <div className="text-center">
            <img src="/iti.jpeg" alt="Wedding Itinerary" className="mx-auto" />
          </div>
        </section>

        <section className="my-8 mx-8 md:mx-16">
          <h2 className="text-3xl md:text-4xl font-semibold text-[#21425d] mb-8 text-center">Share Your Memories</h2> {/* Changed text color */}
          <p className="text-lg text-gray-700 mb-4 text-center">Help us capture the magic of our special day by sharing your photos!</p>
          <div className="text-center">
            <input
              type="file"
              id="photo-upload"
              accept="image/*"
              multiple
              className="hidden"
              onChange={handleImageUpload}
            />
            <button 
              className="bg-[#21425d] text-white text-xl font-light py-3 px-8 rounded hover:bg-[#1a2b3d] transition duration-300"
              onClick={() => document.getElementById('photo-upload').click()}
            >
              Upload Your Photos
            </button>

            {/* Progress bar with percentage */}
            {uploadProgress > 0 && (
              <div className="mt-4 w-full max-w-md mx-auto">
                <div className="bg-gray-200 rounded-full h-2.5 mb-2">
                  <div 
                    className="bg-red-600 h-2.5 rounded-full transition-all duration-300 ease-in-out" 
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
                <p className="text-sm text-gray-600">{`${uploadProgress}% Uploaded`}</p>
              </div>
            )}

            {/* Display upload error, if any */}
            {uploadError && (
              <p className="text-red-500 mt-4">{uploadError}</p>
            )}
          </div>

          {/* Render collage if 6 images are available */}
          {collageImages.length === 6 && (
            <div className="mt-8">
              <h3 className="text-xl font-semibold mb-4 text-[#21425d]">Memories from the Weekend!</h3>
              <div className="grid grid-cols-3 gap-4">
                {collageImages.map((src, index) => (
                  <img key={index} src={src} alt={`Collage ${index + 1}`} className="w-full h-48 object-cover rounded" />
                ))}
              </div>
            </div>
          )}
        </section>

        <section className="my-8 mx-4 md:mx-16">
          <h2 className="text-3xl md:text-4xl font-semibold text-[#21425d] mb-8 text-center">Travel Details</h2> {/* Changed text color */}
          <div className="flex flex-col md:flex-row justify-between">
            <div className="bg-white p-6 rounded-lg shadow-md flex-1 mx-2 mb-4">
              <h3 className="text-xl font-semibold text-[#21425d] mb-2">By Air</h3>
              <p className="text-gray-600">Nearest Airport: Jolly Grant Airport, Dehradun (approximately 35 km from the venue)</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md flex-1 mx-2 mb-4">
              <h3 className="text-xl font-semibold text-[#21425d] mb-2">By Train</h3>
              <p className="text-gray-600">Train Station: Rishikesh Railway Station (approximately 10 km from the venue)</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md flex-1 mx-2 mb-4">
              <h3 className="text-xl font-semibold text-[#21425d] mb-2">By Bus</h3>
              <p className="text-gray-600">Local buses are available from nearby cities to Rishikesh(Nepali Farm Bus Stand).</p>
            </div>
          </div>
        </section>

        <section className="my-8 mx-8 md:mx-16">
          <h2 className="text-3xl md:text-4xl font-semibold text-[#21425d] mb-8 text-center">Contact Details</h2> {/* New section for contact details */}
          <div className="flex flex-col md:flex-row justify-between">
            <div className="bg-white p-6 rounded-lg shadow-md flex-1 mx-2 mb-4">
              <h3 className="text-xl font-semibold text-[#21425d] mb-2">Keshu</h3>
              <p className="text-gray-600">Phone: +91 7302948853</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md flex-1 mx-2 mb-4">
              <h3 className="text-xl font-semibold text-[#21425d] mb-2">Rick</h3>
              <p className="text-gray-600">Phone: +91 8961388276</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md flex-1 mx-2 mb-4">
              <h3 className="text-xl font-semibold text-[#21425d] mb-2">Akash</h3>
              <p className="text-gray-600">Phone: +91 8439166919</p>
            </div>
          </div>
        </section>

        <section className="my-8 mx-8 md:mx-16 mb-16">
          <h2 className="text-3xl md:text-4xl font-semibold text-[#21425d] mb-8 text-center">Accomodation and Venue</h2> {/* Changed text color */}
          <p className="text-center mt-4 mb-4 text-gray-700">
            Venue: Pacific Inn 360 Degree Resort, Rishikesh, Uttarakhand, India
          </p>
          <h3>Accomodation Details:</h3>
          <p className="text-center mt-4 text-gray-700"> Check-in Time : 16th Nov 2024, 12:00 PM   </p>
          <p className="text-center mb-4 text-gray-700"> Check-out Time : 18th Nov 2024, 10:00 AM   </p>
          <br/>
          <div className="aspect-w-16 aspect-h-9">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13806.086071107144!2d78.3809002!3d30.107887!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390917436091cacf%3A0x515ae41c58125252!2sPacific%20Inn%20360%20Degree%20Resort%20Rishikesh!5e0!3m2!1sen!2sin!4v1727003629087!5m2!1sen!2sin" 
              width="100%" 
              height="450" 
              style={{border:0}} 
              allowFullScreen="" 
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Wedding Venue Location"
            ></iframe>
          </div>
        </section>
      </div>
    </div>
  );
};

export default WeddingWebsite;