import logo from './logo.svg';
import './App.css';
import WeddingWebsite from './components/WeddingWebsite';

function App() {
  return (
    <div className="App">
      <WeddingWebsite />
    </div>
  );
}

export default App;
